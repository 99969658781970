import * as React from 'react'

const array = [
  '--cc-yellow-darkest',
  '--cc-yellow-darker',
  '--cc-yellow-dark',
  '--cc-yellow',
  '--cc-yellow-light',
  '--cc-yellow-lighter',
  '--cc-yellow-lightest',
  '--cc-blue-darkest',
  '--cc-blue-darker',
  '--cc-blue-dark',
  '--cc-blue',
  '--cc-blue-light',
  '--cc-blue-lighter',
  '--cc-blue-lightest',
  '--cc-red-darkest',
  '--cc-red-darker',
  '--cc-red-dark',
  '--cc-red',
  '--cc-red-light',
  '--cc-red-lighter',
  '--cc-red-lightest',
  '--cc-dark-blue-darkest',
  '--cc-dark-blue-darker',
  '--cc-dark-blue-dark',
  '--cc-dark-blue',
  '--cc-dark-blue-light',
  '--cc-dark-blue-lighter',
  '--cc-dark-blue-lightest',
  '--cc-white',
  '--cc-black',
  '--cc-gray',
  '--cc-green',
]
const DesigBlock: React.FC<{ color: string }> = ({ color }) => {
  const myself = React.useRef(null)
  const [colorName, setColorName] = React.useState('')

  const getCssVarValue = (color: string): string => {
    const box = myself.current
    console.log(box)
    const value = '' //style.getPropertyValue('background')
    return value
  }
  React.useEffect(() => {
    const box = myself.current
    if (box) {
      const styles = getComputedStyle(box)
      const values = styles
        .getPropertyValue('background-color')
        .slice(4, -1)
        .split(', ')
      const hex = values
        .map((val) => parseInt(val).toString(16).padStart(2, '0'))
        .join('')
      setColorName(`#${hex}`.toUpperCase())
    }
  }, [myself.current])

  return (
    <div
      style={{
        padding: '16px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <div>{color.replace(/--cc-/g,'').replace(/-/g,' ')}</div>
      <div
        ref={myself}
        style={{ background: `var(${color})`, height: '24px', width: '24px' }}
      />
      <div>{colorName}</div>
    </div>
  )
}

const DesignPage = () => (
  <div
    style={{
      display: 'flex',
      flexWrap: 'wrap',
      background: 'rgba(0,0,0,0.05)',
      justifyContent: 'center',
    }}
  >
    {array.map((color) => (
      <DesigBlock color={color} key={color} />
    ))}
  </div>
)

export default DesignPage
